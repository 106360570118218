import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

const TermsAndConditionsPage = ({ pageContext }) => {
  const dispatch = React.useContext(GlobalDispatchContext);

  React.useEffect(() => {
    dispatch({
      type: "setCurrentCategory",
      category: {},
    });
  }, []);

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading
            style={{
              textTransform: "uppercase",
            }}
          >
            Terms and Conditions
          </SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>
              <span style={{ color: "#333333" }}>
                Welcome to AIProduct reviews!
              </span>
            </p>
            <p>
              <span style={{ color: "#333333" }}>
                These terms and conditions outline the rules and regulations for
                the use of PJK MARKETING LTD's Website, located at
                https://ai-productreviews.com/.
              </span>
            </p>
            <p>
              <span style={{ color: "#333333" }}>
                By accessing this website we assume you accept these terms and
                conditions. Do not continue to use AIProduct reviews if you do
                not agree to take all of the terms and conditions stated on this
                page.
              </span>
            </p>
            <p>
              <span style={{ color: "#333333" }}>
                The following terminology applies to these Terms and Conditions,
                Privacy Statement and Disclaimer Notice and all Agreements:
                "Client", "You" and "Your" refers to you, the person log on this
                website and compliant to the Company's terms and conditions.
                "The Company", "Ourselves", "We", "Our" and "Us", refers to our
                Company. "Party", "Parties", or "Us", refers to both the Client
                and ourselves. All terms refer to the offer, acceptance and
                consideration of payment necessary to undertake the process of
                our assistance to the Client in the most appropriate manner for
                the express purpose of meeting the Client's needs in respect of
                provision of the Company's stated services, in accordance with
                and subject to, prevailing law of gb. Any use of the above
                terminology or other words in the singular, plural,
                capitalization and/or he/she or they, are taken as
                interchangeable and therefore as referring to same.
              </span>
            </p>
            <h3>Cookies</h3>
            <p>
              <span style={{ color: "#333333" }}>
                We employ the use of cookies. By accessing AIProduct reviews,
                you agreed to use cookies in agreement with the PJK MARKETING
                LTD's Privacy Policy.
              </span>
            </p>
            <p>
              <span style={{ color: "#333333" }}>
                Most interactive websites use cookies to let us retrieve the
                user's details for each visit. Cookies are used by our website
                to enable the functionality of certain areas to make it easier
                for people visiting our website. Some of our
                affiliate/advertising partners may also use cookies.
              </span>
            </p>
            <h3>License</h3>
            <p>
              <span style={{ color: "#333333" }}>
                Unless otherwise stated, PJK MARKETING LTD and/or its licensors
                own the intellectual property rights for all material on Ai
                Product reviews. All intellectual property rights are reserved.
                You may access this from AIProduct reviews for your own personal
                use subjected to restrictions set in these terms and conditions.
              </span>
            </p>
            <p>
              <span style={{ color: "#333333" }}>You must not:</span>
            </p>
            <ul>
              <li>
                <span style={{ color: "#333333" }}>
                  Republish material from AIProduct reviews
                </span>
              </li>
              <li>
                <span style={{ color: "#333333" }}>
                  Sell, rent or sub-license material from AIProduct reviews
                </span>
              </li>
              <li>
                <span style={{ color: "#333333" }}>
                  Reproduce, duplicate or copy material from AIProduct reviews
                </span>
              </li>
              <li>
                <span style={{ color: "#333333" }}>
                  Redistribute content from AIProduct reviews
                </span>
              </li>
            </ul>
            <p>
              <span style={{ color: "#333333" }}>
                This Agreement shall begin on the date hereof. Our Terms and
                Conditions were created with the help of the{" "}
              </span>
              <a
                href="https://www.termsandconditionsgenerator.com/"
                target="_blank"
                rel="noopener"
              >
                <span style={{ color: "#333333" }}>
                  Free Terms and Conditions Generator
                </span>
              </a>
              <span style={{ color: "#333333" }}>.</span>
            </p>
            <p>
              <span style={{ color: "#333333" }}>
                Parts of this website offer an opportunity for users to post and
                exchange opinions and information in certain areas of the
                website. PJK MARKETING LTD does not filter, edit, publish or
                review Comments prior to their presence on the website. Comments
                do not reflect the views and opinions of PJK MARKETING LTD,its
                agents and/or affiliates. Comments reflect the views and
                opinions of the person who post their views and opinions. To the
                extent permitted by applicable laws, PJK MARKETING LTD shall not
                be liable for the Comments or for any liability, damages or
                expenses caused and/or suffered as a result of any use of and/or
                posting of and/or appearance of the Comments on this website.
              </span>
            </p>
            <p>
              <span style={{ color: "#333333" }}>
                PJK MARKETING LTD reserves the right to monitor all Comments and
                to remove any Comments which can be considered inappropriate,
                offensive or causes breach of these Terms and Conditions.
              </span>
            </p>
            <p>
              <span style={{ color: "#333333" }}>
                You warrant and represent that:
              </span>
            </p>
            <ul>
              <li>
                <span style={{ color: "#333333" }}>
                  You are entitled to post the Comments on our website and have
                  all necessary licenses and consents to do so;
                </span>
              </li>
              <li>
                <span style={{ color: "#333333" }}>
                  The Comments do not invade any intellectual property right,
                  including without limitation copyright, patent or trademark of
                  any third party;
                </span>
              </li>
              <li>
                <span style={{ color: "#333333" }}>
                  The Comments do not contain any defamatory, libelous,
                  offensive, indecent or otherwise unlawful material which is an
                  invasion of privacy
                </span>
              </li>
              <li>
                <span style={{ color: "#333333" }}>
                  The Comments will not be used to solicit or promote business
                  or custom or present commercial activities or unlawful
                  activity.
                </span>
              </li>
            </ul>
            <p>
              <span style={{ color: "#333333" }}>
                You hereby grant PJK MARKETING LTD a non-exclusive license to
                use, reproduce, edit and authorize others to use, reproduce and
                edit any of your Comments in any and all forms, formats or
                media.
              </span>
            </p>
            <h3>Hyperlinking to our Content</h3>
            <p>
              <span style={{ color: "#333333" }}>
                The following organizations may link to our Website without
                prior written approval:
              </span>
            </p>
            <ul>
              <li>
                <span style={{ color: "#333333" }}>Government agencies;</span>
              </li>
              <li>
                <span style={{ color: "#333333" }}>Search engines;</span>
              </li>
              <li>
                <span style={{ color: "#333333" }}>News organizations;</span>
              </li>
              <li>
                <span style={{ color: "#333333" }}>
                  Online directory distributors may link to our Website in the
                  same manner as they hyperlink to the Websites of other listed
                  businesses; and
                </span>
              </li>
              <li>
                <span style={{ color: "#333333" }}>
                  System wide Accredited Businesses except soliciting non-profit
                  organizations, charity shopping malls, and charity fundraising
                  groups which may not hyperlink to our Web site.
                </span>
              </li>
            </ul>
            <p>
              <span style={{ color: "#333333" }}>
                These organizations may link to our home page, to publications
                or to other Website information so long as the link: (a) is not
                in any way deceptive; (b) does not falsely imply sponsorship,
                endorsement or approval of the linking party and its products
                and/or services; and (c) fits within the context of the linking
                party's site.
              </span>
            </p>
            <p>
              <span style={{ color: "#333333" }}>
                We may consider and approve other link requests from the
                following types of organizations:
              </span>
            </p>
            <ul>
              <li>
                <span style={{ color: "#333333" }}>
                  commonly-known consumer and/or business information sources;
                </span>
              </li>
              <li>
                <span style={{ color: "#333333" }}>
                  dot.com community sites;
                </span>
              </li>
              <li>
                <span style={{ color: "#333333" }}>
                  associations or other groups representing charities;
                </span>
              </li>
              <li>
                <span style={{ color: "#333333" }}>
                  online directory distributors;
                </span>
              </li>
              <li>
                <span style={{ color: "#333333" }}>internet portals;</span>
              </li>
              <li>
                <span style={{ color: "#333333" }}>
                  accounting, law and consulting firms; and
                </span>
              </li>
              <li>
                <span style={{ color: "#333333" }}>
                  educational institutions and trade associations.
                </span>
              </li>
            </ul>
            <p>
              <span style={{ color: "#333333" }}>
                We will approve link requests from these organizations if we
                decide that: (a) the link would not make us look unfavorably to
                ourselves or to our accredited businesses; (b) the organization
                does not have any negative records with us; (c) the benefit to
                us from the visibility of the hyperlink compensates the absence
                of PJK MARKETING LTD; and (d) the link is in the context of
                general resource information.
              </span>
            </p>
            <p>
              <span style={{ color: "#333333" }}>
                These organizations may link to our home page so long as the
                link: (a) is not in any way deceptive; (b) does not falsely
                imply sponsorship, endorsement or approval of the linking party
                and its products or services; and (c) fits within the context of
                the linking party's site.
              </span>
            </p>
            <p>
              <span style={{ color: "#333333" }}>
                If you are one of the organizations listed in paragraph 2 above
                and are interested in linking to our website, you must inform us
                by sending an e-mail to PJK MARKETING LTD. Please include your
                name, your organization name, contact information as well as the
                URL of your site, a list of any URLs from which you intend to
                link to our Website, and a list of the URLs on our site to which
                you would like to link. Wait 2-3 weeks for a response.
              </span>
            </p>
            <p>
              <span style={{ color: "#333333" }}>
                Approved organizations may hyperlink to our Website as follows:
              </span>
            </p>
            <ul>
              <li>
                <span style={{ color: "#333333" }}>
                  By use of our corporate name; or
                </span>
              </li>
              <li>
                <span style={{ color: "#333333" }}>
                  By use of the uniform resource locator being linked to; or
                </span>
              </li>
              <li>
                <span style={{ color: "#333333" }}>
                  By use of any other description of our Website being linked to
                  that makes sense within the context and format of content on
                  the linking party's site.
                </span>
              </li>
            </ul>
            <p>
              <span style={{ color: "#333333" }}>
                No use of PJK MARKETING LTD's logo or other artwork will be
                allowed for linking absent a trademark license agreement.
              </span>
            </p>
            <h3>iFrames</h3>
            <p>
              <span style={{ color: "#333333" }}>
                Without prior approval and written permission, you may not
                create frames around our Webpages that alter in any way the
                visual presentation or appearance of our Website.
              </span>
            </p>
            <h3>Content Liability</h3>
            <p>
              <span style={{ color: "#333333" }}>
                We shall not be hold responsible for any content that appears on
                your Website. You agree to protect and defend us against all
                claims that is rising on your Website. No link(s) should appear
                on any Website that may be interpreted as libelous, obscene or
                criminal, or which infringes, otherwise violates, or advocates
                the infringement or other violation of, any third party rights.
              </span>
            </p>
            <h3>Reservation of Rights</h3>
            <p>
              <span style={{ color: "#333333" }}>
                We reserve the right to request that you remove all links or any
                particular link to our Website. You approve to immediately
                remove all links to our Website upon request. We also reserve
                the right to amen these terms and conditions and it's linking
                policy at any time. By continuously linking to our Website, you
                agree to be bound to and follow these linking terms and
                conditions.
              </span>
            </p>
            <h3>Removal of links from our website</h3>
            <p>
              <span style={{ color: "#333333" }}>
                If you find any link on our Website that is offensive for any
                reason, you are free to contact and inform us any moment. We
                will consider requests to remove links but we are not obligated
                to or so or to respond to you directly.
              </span>
            </p>
            <p>
              <span style={{ color: "#333333" }}>
                We do not ensure that the information on this website is
                correct, we do not warrant its completeness or accuracy; nor do
                we promise to ensure that the website remains available or that
                the material on the website is kept up to date.
              </span>
            </p>
            <h3>Disclaimer</h3>
            <p>
              <span style={{ color: "#333333" }}>
                To the maximum extent permitted by applicable law, we exclude
                all representations, warranties and conditions relating to our
                website and the use of this website. Nothing in this disclaimer
                will:
              </span>
            </p>
            <ul>
              <li>
                limit or exclude our or your liability for death or personal
                injury;
              </li>
              <li>
                limit or exclude our or your liability for fraud or fraudulent
                misrepresentation;
              </li>
              <li>
                limit any of our or your liabilities in any way that is not
                permitted under applicable law; or
              </li>
              <li>
                exclude any of our or your liabilities that may not be excluded
                under applicable law.
              </li>
            </ul>
            <p>
              <span style={{ color: "#333333" }}>
                The limitations and prohibitions of liability set in this
                Section and elsewhere in this disclaimer: (a) are subject to the
                preceding paragraph; and (b) govern all liabilities arising
                under the disclaimer, including liabilities arising in contract,
                in tort and for breach of statutory duty.
              </span>
            </p>
            <p>
              <span style={{ color: "#333333" }}>
                As long as the website and the information and services on the
                website are provided free of charge, we will not be liable for
                any loss or damage of any nature.
              </span>
            </p>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default TermsAndConditionsPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Terms and Conditions | AI Product Reviews"}
      description={
        "Review our usage terms and conditions. Understand the policies governing your use of AI Product Reviews."
      }
      pathname={location.pathname}
    />
  );
};
